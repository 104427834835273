const setUsersLists = (objectBaunir,forUser='B') => {

    const listsFromObject = (objectBaunir) => {
        let oKeys = Object.keys(objectBaunir)
        let otherKeys = oKeys.filter(o => objectBaunir[o].A); // Öll svör frá 'A'
        let iselfKeys = oKeys.filter(o => objectBaunir[o].B); // Öll svör frá 'B'
    
        let otherKannskiKEYS = otherKeys.filter(key => objectBaunir[key].A.status === 1)
        let otherVilEkkiKEYS = otherKeys.filter(key => objectBaunir[key].A.status === 2 )
        let otherCommentKEYS = otherKeys.filter(key => objectBaunir[key].A.comment )
        let otherCommentList = {}
        otherCommentKEYS.forEach(name => otherCommentList[`${name}`] = `${objectBaunir[name].A.comment}` )
    
        let iselfKannskiKEYS = iselfKeys.filter(key => objectBaunir[key].B.status === 1 )
        let iselfVilEkkiKEYS = iselfKeys.filter(key => objectBaunir[key].B.status === 2 )
        let iselfCommentKEYS = iselfKeys.filter(key => objectBaunir[key].B.comment )
        let iselfCommentList = {}
        iselfCommentKEYS.forEach(name => iselfCommentList[`${name}`] = `${objectBaunir[name].B.comment}` )
    
        const consoleListLengths = () => {
            console.log('A')
            console.log('A.status.Kannski: ', otherKannskiKEYS.length)
            console.log('A.status.VilEkki: ', otherVilEkkiKEYS.length)
            console.log('A.status.Comment: ', otherCommentKEYS.length)
            console.log('B')
            console.log('B.status.Kannski: ', iselfKannskiKEYS.length)
            console.log('B.status.VilEkki: ', iselfVilEkkiKEYS.length)
            console.log('B.status.Comment: ', iselfCommentKEYS.length)
        }
        // consoleListLengths();
    
        const AllLists = {
            'A': {
                Kannski: [...otherKannskiKEYS],
                VilEkki: [...otherVilEkkiKEYS],
                Comment: [...otherCommentKEYS],
                AthText: {...otherCommentList},
                KannskiFjoldi: otherKannskiKEYS.length,
                VilEkkiFjoldi: otherVilEkkiKEYS.length,
            },
            'B': {
                Kannski: [...iselfKannskiKEYS],
                VilEkki: [...iselfVilEkkiKEYS], 
                Comment: [...iselfCommentKEYS], 
                AthText: {...iselfCommentList},
                KannskiFjoldi: iselfKannskiKEYS.length,
                VilEkkiFjoldi: iselfVilEkkiKEYS.length,
            },
        }
        
        return AllLists;
    }

    const iself = (forUser==='A') ? 'A' : 'B';
    const other = (forUser==='B') ? 'A' : 'B';

    const usersLists = {
        'iself': {...listsFromObject(objectBaunir)[iself]},
        'other': {...listsFromObject(objectBaunir)[other]},
    }

    return usersLists;
}

export default setUsersLists;