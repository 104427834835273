import React from "react";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import { filteredBaunaHeiti } from "./atoms";

import setUsersLists from "./utils/setUsersLists";

import { rdb } from "../firebase/realtimeConfig";
import rdbUpdate from "../firebase/rdbUpdate";
import { rdbComment } from "../firebase/rdbUpdate";
import { onValue, ref } from "firebase/database";

export default function Bumbska() {

const [dataLoaded, setDataLoaded] = useState(false);

const [filtered] = useAtom(filteredBaunaHeiti);

const [kannskiList, setKannskiList] = useState([]);
const [ekkiList, setEkkiList] = useState([]);
const [commentList, setCommentList] = useState({});

const [commentsLoaded, setCommentsLoaded] = useState(undefined);
const [showKannski, setShowKannski] = useState(true);
const [showEkki, setShowEkki] = useState(true);
const [showRest, setShowRest] = useState(true);
const [showOnlyComments, setShowOnlyComments] = useState(false);

const [firebaseData, setFirebaseData] = useState({});
const [firebaseBaunaCount, setFirebaseBaunaCount] = useState(-1);

const [makiKannski, setMakiKannski] = useState([]);
const [makiEkki, setMakiEkki] = useState([]);

const [sammalaKannski, setSammalaKannski] = useState([]);
const [sammalaEkki, setSammalaEkki] = useState([]);

const [user, setUser] = useState('Agla');
const [iself, setiself] = useState('A');
const [other, setother] = useState('B');

useEffect(() => {
    const query = ref(rdb, "stelpubaunir");
    return onValue(query, (snapshot) => {
        const data = snapshot.val();
        setFirebaseData({...data})
        if (snapshot.exists()) {
            setFirebaseBaunaCount(Object.keys(data).length)
        }
    });
  }, []);

  const updateUserLists = () => {
    const usersLists = setUsersLists(firebaseData,iself)
    const iselfLists = usersLists.iself
    const otherLists = usersLists.other
    let sammalaK = iselfLists.Kannski.filter(k => otherLists.Kannski.includes(k));
    let sammalaE = iselfLists.VilEkki.filter(e => otherLists.VilEkki.includes(e));
    let KANNSKI = iselfLists.Kannski;
    let VILEKKI = iselfLists.VilEkki;
    let COMMENT = iselfLists.AthText;
    let MAKIKANNSKI = otherLists.VilEkki;
    let MAKIKEKKI = otherLists.VilEkki;
    const setUseStateLists = () => {
        setKannskiList([...KANNSKI])
        setEkkiList([...VILEKKI])
        setCommentList({...COMMENT})
        setMakiKannski([...MAKIKANNSKI])
        setMakiEkki([...MAKIKEKKI])
        setSammalaKannski([...sammalaK])
        setSammalaEkki([...sammalaE])
        setDataLoaded(true)
    }
    setUseStateLists();
  }

// Setting UserLists for both 'A' & 'B' / 'iself' and 'other'
// New version where useState-set-Lists functions are only updated here
useEffect(() => {
    if (firebaseData && firebaseBaunaCount > 0) {
        updateUserLists()
    }
}, [firebaseData, firebaseBaunaCount]);

useEffect(() => {
    if (firebaseData && firebaseBaunaCount > 0) {
        if (user === 'Baldvin') {
            setiself('B'); 
            setother('A');
        } else if (user === 'Agla') {
            setiself('A'); 
            setother('B');
        }
        updateUserLists()
    }
}, [user]);

useEffect(() => {
    if (Object.keys(commentList).length > 0) {
        setCommentsLoaded(true)
    }
  }, [commentList]);

  const addToKannski = (e) => {
    if (e.target.checked) {
        document.querySelectorAll(`#${e.target.id}`)[1].checked=false;       
        rdbUpdate(iself, e.target.id, 1)
    }
    if (!e.target.checked) {
        rdbUpdate(iself, e.target.id, 0)
    }
  }
  const addToEkki = (e) => {
    if (e.target.checked) {
        document.querySelectorAll(`#${e.target.id}`)[0].checked=false;
        rdbUpdate(iself, e.target.id, 2)
    }
    if (!e.target.checked) {
        rdbUpdate(iself, e.target.id, 0)
    }
  }

  function debounce(cb, delay = 250) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

const commentHandler = debounce(e => {
    let updatedCommentList = {...commentList, [`${e.target.id}`]: `${e.target.value}`}
    setCommentList(updatedCommentList)
    rdbComment(iself,e.target.id,e.target.value)
  }, 500)

  const kannskiClickHandler = () => { setShowKannski(!showKannski) }
  const ekkiClickHandler = () => { setShowEkki(!showEkki) }
  const commentsClickHandler = () => {setShowOnlyComments(!showOnlyComments)}
  const restClickHandler = () => {setShowRest(!showRest)}

  const RenderStats = () => {
    return (
        <div className="grid stats">
            <div className="filt-and-total">
                <div className="stats-item stats-filtered">
                    <span className="stats-label filtered-label" id="filtered-label">#</span> 
                    <div className="stats-filtered-numbers">
                        <span className="filtered stats-percentage filtered-percentage">{`${Math.round(100*(filtered.length / 2365),0)} %`}</span> 
                        <span className="filtered filtered-number" id="filtered-number">{filtered.length} </span>
                    </div>
                </div>
                <div className="stats-item stats-total">
                    <span className="stats-label all-label" id="total-label">Samtals</span>
                    <span className="stats-number statnumber" id="total-number">2365</span>
                </div>
            </div>
            
            <div 
                className="stats-item stats-maybe" id={showKannski ? "show-list" : "hide-list"}
                // onClick={setShowKannski(!showKannski)}
                onClick={kannskiClickHandler}
            >
                <span className="stats-percentage maybe-percentage">{`${Math.round(100*(kannskiList.length / 2365),0)} %`}</span> 
                <span className="stats-label maybe-label">Kannski: </span> 
                <span className="stats-number maybe-number">{kannskiList.length}</span>  
            </div>
            <div 
                className="stats-item stats-not" id={showEkki ? "show-list" : "hide-list"}
                // onClick={() => setShowEkki(!showEkki)}
                onClick={ekkiClickHandler}
            >
                <span className="stats-percentage eliminated-percentage">{`${Math.round(100*(ekkiList.length / 2365),0)} %`}</span> 
                <span className="stats-label eliminated-label">Ekki:</span> 
                <span className="stats-number eliminated-number">{ekkiList.length}</span>  
            </div>

            <div 
                className="stats-item stats-rest" id={showRest ? "show-list" : "hide-list"}
                // onClick={setShowRest(!showRest)}
                onClick={restClickHandler}
            >
                <span className="stats-label rest-label">Eftir:</span>
                <div className="rest-stats-numbers">
                    <span className="stats-percentage rest-percentage">{`${Math.round(100*((2365 - kannskiList.length - ekkiList.length) / 2365),0)} %`}</span>
                    <span className="stats-number restnumber">{2365 - kannskiList.length - ekkiList.length}</span>
                </div>
            </div>

        </div>
    );
  }
  const RenderListRow = ({n}) => {
    return (
        <tr key={n} 
            className={`
                nafnalina ${sammalaKannski.includes(n) ? "sammalaPlus-Lina" : ""} 
                ${sammalaEkki.includes(n) ? "sammalaEkkiLina" : ""}`
            }
            id={
                makiKannski.includes(n) 
                ? (kannskiList.includes(n)) ? "sammalaKannskiLina" : "makiKannski" 
                : makiEkki.includes(n)
                    ? ekkiList.includes(n) 
                        ? "sammalaEkkiLina" :"makiEkki"
                        : ""
            }
        >
            <td className={`stafur ${n[0]}`} id={makiKannski.includes(n) ? "makiKannski" : ""}>{n}</td>
            <td className="kannski" >              
                <label className="checkbox-wrap" id="maybe-box">.
                    <input 
                        type="checkbox"
                        id={n} name="fatchance" 
                        value="kannski"
                        className="checkbox maybe-box"
                        onClick={addToKannski}
                        checked={ kannskiList.includes(n) ? true : false}
                        onChange={addToKannski}
                    />
                    <span className="checkmark"
                        id={
                            (kannskiList.includes(n) && makiKannski.includes(n))
                            ? "sammalaKannski"
                            : "kannskiBox"
                        }
                    >
                        {(kannskiList.includes(n) && makiKannski.includes(n)) ? ":)" : ""}
                    </span>
                </label>
            </td>
            <td className="onEkkiList" 
                id={
                    (ekkiList.includes(n) && makiEkki.includes(n))
                    ? "sammalaEkki"
                    : "ekkiBox"
                }
            >
                <label className="checkbox-wrap" id="not-box">.
                    <input 
                        type="checkbox" 
                        id={n} name="fatchance" 
                        value="no" 
                        className="checkbox not-box"
                        checked={ ekkiList.includes(n) ? true : false}
                        onClick={addToEkki}
                        onChange={addToEkki}
                    />
                    <span className="checkmark"></span>
                </label>
            </td>
            <td className={`${showOnlyComments ? "only-comments-white" : "comment"}`}>
                {
                (commentsLoaded && commentList[n]) 
                ? <input 
                    type="text" className={`${showOnlyComments ? "commentbox smallfont only-comments-white" : "commentbox"}`}
                    id={n} key={n}
                    value={commentList[n]}
                    placeholder="loaded"
                    onChange={(e) => commentHandler(e)}
                 /> 
                 : <input 
                        type="text" className="commentbox"
                        placeholder=".."
                        id={n} key={n}
                        onChange={(e) => commentHandler(e)}
                />}
            </td>
        </tr>
    );
  }
  const RenderKannskiLIST = () => <tbody>{filtered.map((n) => kannskiList.includes(n) && <RenderListRow n={n} key={n}/>)}</tbody>
  const RenderOnlyCommentLIST = () => <tbody>{filtered.map((n) => Object.keys(commentList).includes(n) && <RenderListRow n={n} key={n}/>)}</tbody>
  const RenderEkkiLIST = () => <tbody>{filtered.map((n) => ekkiList.includes(n) && <RenderListRow n={n} key={n}/> )}</tbody>
  const RenderRestLIST = () => <tbody>{filtered.map((n) => !kannskiList.includes(n) && !ekkiList.includes(n) && <RenderListRow n={n} key={n}/>)}</tbody>

  const UserButton = () => {
    return (
        <div className="userinfo-wrap">
            <div className="UserInfo" id={user==='Baldvin' ? "BALDVIN" : "AGLA"}>
                {user}
            </div>
        </div>
    );
  }

  return (
    <div className="container">
        <RenderStats />
        <UserButton />   
        { 
        <table>
            <thead>
                <tr className="headerlina">
                    <th id="name-header">Baunanafn</th>
                    <th className="checkbox-header" id="maybe-checkbox-header">Kannski</th>
                    <th className="checkbox-header" id="not-checkbox-header">Ekki!</th>
                    <th className={`${showOnlyComments ? "Show-Only-Comments" : ""}`} id="comment-header" onClick={commentsClickHandler}>
                        {`ATHUGASEMDIR: `} 
                        <span className={`${showOnlyComments ? "only-comments-white" : ""}`} id="Comment-Count">
                            {Object.keys(commentList).length}
                        </span>
                        </th>
                </tr>
            </thead>

            { (!showOnlyComments && showKannski) ? <RenderKannskiLIST/> : <></> }
            { (!showOnlyComments && showEkki)    ? <RenderEkkiLIST   /> : <></> }
            { (!showOnlyComments && showRest)    ? <RenderRestLIST   /> : <></> }
            { (showOnlyComments) ? <RenderOnlyCommentLIST/> : <></> }
        </table>
        }
    </div>
  );
}
