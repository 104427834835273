import React, {useState} from "react";
import { useAtom } from "jotai";

import { filterAtom } from "./atoms";
import { filteredBaunaHeiti } from "./atoms";

export default function FilterInput() {
  const [textEntered, setTextEntered] = useState(false)
  const [filter, filterSet] = useAtom(filterAtom);
  const [filtered] = useAtom(filteredBaunaHeiti);

  const ClearButton = () => {
    const clearTextClicker = (evt) => {
      document.querySelector('#search-input').value = "";
      filterSet("")
    }
    return (
      <>
        <div 
          className={filter.length > 0 ? "clear-button text-to-erase" : "clear-button"}
          id={filter.length > 0 ? "text-to-erase" : ""}
          onClick={(evt) => clearTextClicker(evt)}
        >
          X
        </div>
      </>
    )
  }
  return (
    <div className="search-box">
        <div className="search-row">
            <div className="space"></div>
            {/* <div className="search-input-label"> Baunanafn: </div> */}
            <input id="search-input" name="search-input" value={filter} className="search-input" onChange={(evt) => filterSet(evt.target.value)} />
            <ClearButton />
            <div className="space"></div>
        </div>
    </div>
  );
}
