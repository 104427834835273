import React from "react";
import { Provider } from "jotai";

import "./BaunaHeitaApp.css";

import FilterInput from "./FilterInput";
// import BumbuBumbska from "./BumbuBumbska";
import Bumbska from "./Bumbska";
// import Babbi from "./Babbi";

function BaunaHeitaApp() {
  return (
    <div className="App">
      <FilterInput />
      {/* <BumbuBumbska /> */}
      <Bumbska />
      {/* <Babbi /> */}
    </div>
  );
}

export default () => (
  <Provider>
    <React.Suspense fallback={<div>Loading</div>}>
      <div className="WindowSize">
        <BaunaHeitaApp />
      </div>
    </React.Suspense>
  </Provider>
);
